import React from 'react';
/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({fill}) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100%"  viewBox="0 0 1000 291"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,291.000000) scale(0.100000,-0.100000)" fill={fill} >
<path d="M753 2385 c-20 -8 -39 -27 -48 -47 -12 -26 -159 -564 -189 -690 -4
-16 0 -42 8 -60 22 -52 593 -821 624 -840 31 -21 77 -23 109 -5 24 13 645 793
670 841 9 17 12 41 9 60 -3 17 -58 179 -121 360 -83 239 -122 337 -141 357
-30 31 -80 44 -117 30 -15 -6 -88 -83 -164 -173 -76 -89 -149 -175 -163 -191
l-26 -29 -168 192 c-190 217 -207 228 -283 195z m252 -340 c105 -123 195 -223
202 -222 7 1 96 103 199 226 l187 224 112 -323 112 -323 -186 -237 -186 -237
-47 42 c-48 43 -131 75 -194 75 -59 0 -135 -30 -179 -71 l-44 -40 -174 234
-175 234 87 322 c47 176 88 321 91 320 3 0 91 -101 195 -224z m275 -917 c24
-13 53 -35 63 -50 l20 -27 -79 -99 -79 -99 -78 104 -77 103 27 30 c29 32 89
59 133 60 14 0 46 -10 70 -22z"/>
<path d="M4557 2053 c-4 -3 -7 -91 -7 -195 l0 -190 -30 30 c-56 57 -114 77
-220 77 -82 0 -103 -4 -150 -26 -110 -51 -168 -128 -191 -252 -10 -55 -10 -83
-1 -139 22 -126 81 -205 192 -257 47 -22 69 -26 150 -26 84 0 102 3 152 28 31
15 69 42 83 58 l25 31 0 -56 0 -56 50 0 50 0 0 490 0 490 -48 0 c-27 0 -52 -3
-55 -7z m-152 -392 c95 -43 140 -119 140 -236 0 -90 -17 -135 -69 -187 -45
-45 -101 -68 -170 -68 -75 0 -133 27 -181 83 -90 105 -84 259 15 358 26 26 63
52 81 58 56 16 140 12 184 -8z"/>
<path d="M7680 1570 l0 -490 55 0 55 0 0 213 c0 192 2 216 21 257 25 57 44 77
93 102 78 40 180 34 242 -14 57 -45 64 -80 64 -330 l0 -228 56 0 55 0 -3 258
c-3 241 -4 260 -24 297 -30 55 -70 94 -124 119 -36 17 -64 21 -140 21 -102 0
-158 -18 -214 -70 l-26 -24 0 189 0 190 -55 0 -55 0 0 -490z"/>
<path d="M8577 2048 c-28 -10 -44 -45 -38 -83 11 -73 118 -75 144 -3 11 31
-12 76 -45 87 -17 6 -31 11 -32 10 -1 0 -13 -5 -29 -11z"/>
<path d="M8910 1570 l0 -490 50 0 50 0 0 490 0 490 -50 0 -50 0 0 -490z"/>
<path d="M9260 1570 l0 -490 50 0 50 0 0 490 0 490 -50 0 -50 0 0 -490z"/>
<path d="M2603 1995 c-239 -52 -393 -284 -354 -534 36 -237 224 -391 476 -391
131 0 225 33 315 109 l35 30 -37 37 -37 38 -38 -30 c-79 -64 -168 -90 -271
-81 -132 13 -248 94 -305 216 -26 54 -30 75 -30 145 0 153 69 263 209 332 67
34 81 37 156 37 96 -1 179 -27 233 -74 18 -16 37 -29 42 -29 5 0 24 16 43 35
l34 35 -34 30 c-107 93 -276 130 -437 95z"/>
<path d="M7043 1995 c-239 -52 -393 -284 -354 -534 36 -237 224 -391 476 -391
131 0 225 33 315 109 l35 30 -37 37 -37 38 -38 -30 c-79 -64 -168 -90 -271
-81 -132 13 -248 94 -305 216 -26 54 -30 75 -30 145 0 153 69 263 209 332 67
34 81 37 156 37 96 -1 179 -27 233 -74 18 -16 37 -29 42 -29 5 0 24 16 43 35
l34 35 -34 30 c-107 93 -276 130 -437 95z"/>
<path d="M5710 1541 l0 -461 55 0 55 0 2 352 3 352 279 -352 278 -352 49 0 49
0 0 460 0 460 -55 0 -55 0 -2 -356 -3 -356 -280 354 -280 353 -48 3 -47 3 0
-460z"/>
<path d="M3389 1767 c-100 -28 -181 -97 -223 -186 -31 -67 -30 -245 1 -313 32
-70 94 -131 166 -164 55 -26 73 -29 162 -29 87 0 107 3 157 27 104 48 170 139
191 262 27 160 -48 313 -189 382 -65 32 -191 42 -265 21z m229 -120 c156 -103
158 -333 5 -441 -45 -31 -120 -44 -186 -32 -52 10 -118 59 -152 113 -28 44
-30 55 -30 138 0 78 3 95 25 130 57 92 122 127 226 123 58 -3 79 -8 112 -31z"/>
<path d="M5063 1762 c-129 -46 -203 -138 -222 -278 -34 -246 133 -426 382
-412 53 3 98 12 129 26 56 24 118 72 118 89 0 7 -13 27 -29 45 l-29 32 -35
-31 c-113 -99 -304 -79 -389 42 -16 22 -31 57 -35 78 l-6 37 281 0 c228 0 281
3 285 14 3 7 2 42 -3 77 -21 175 -142 288 -318 296 -57 3 -91 -1 -129 -15z
m205 -98 c20 -8 50 -27 66 -42 28 -26 76 -114 76 -139 0 -10 -53 -13 -230 -13
-225 0 -230 0 -230 20 0 57 58 137 123 169 51 25 141 28 195 5z"/>
<path d="M8560 1425 l0 -345 50 0 50 0 0 345 0 345 -50 0 -50 0 0 -345z"/>
</g>
</svg>

  );  
}